$tablet-width: 768px;
$desktop-width: 1024px;

@mixin tablet {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

body{
  background: #E5E5E5;
  font-size: 24px;
  line-height: 1.2;
}

p a{
  color: inherit;
}

.logo{
  max-width: 150px;
  margin-top: 20px;
  grid-column: span 1;
  position: relative;
  @include tablet{
    grid-column: span 4;
    margin-bottom: 20px;
  }
  span{
    position: absolute;
    right: 0;
    top: 0px;
    font-size: 48px;
    display: block;
    width: 100%;
    transform: translateX(calc(100% + 18px));
    @include tablet{
      font-size: 44px
    }
    @include mobile{
      font-size: 28px;
      transform: translateX(calc(100% + 10px));
    }
  }
}

.logo svg{
  display: block;
  transform: translateX(10px);
  @include tablet{
    transform: translate(0);
  }
}
.number{
  width: 33.33%;
  padding-right: 25px;
  box-sizing: border-box;
  max-width: 100px;
}

.multi-number{
  grid-column: span 2;
  border-bottom: 2px solid #000000;
  border-top: 2px solid #000000;
  padding: 20px 0;
  background-size: 80% 80%;
  background-position: center;
  background-repeat: no-repeat;
  font-size: 14px;
  @include mobile{
    grid-column: span 8;
    padding: 20px 0 12px;
    &:last-child{
      border-top: 0;
    }
    &:nth-child(2){
      border-top: 0;
    }
    &:nth-child(3){
      border-top: 0;
    }
  }
}

.multi-number:nth-child(1){
  background-image: radial-gradient(50% 50% at 50% 50%, #F79DFF 0%, rgba(255, 255, 255, 0) 100%);
}

.multi-number:nth-child(2){
  background-image: radial-gradient(50% 50% at 50% 50%, #9EFF00 0%, rgba(255, 255, 255, 0) 100%);
}

.multi-number:nth-child(3){
  background-image: radial-gradient(50% 50% at 50% 50%, #39F3FF 0%, rgba(255, 255, 255, 0) 100%);
}

.multi-number:nth-child(4){
  background-image: radial-gradient(50% 50% at 50% 50%, #AE9AFF 0%, rgba(255, 255, 255, 0) 100%);
}

.multi-number > div{
  margin-top: 20px;
  @include mobile{
    margin-top: 12px;
  }
}

.multi-number:first-child{
  grid-column: 2 / 4;
  @include tablet{
    grid-column: span 2;
  }
  @include mobile{
    grid-column: span 8;
  }
}

.grid{
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-column-gap: 20px;
  padding: 0 10px;
  @include tablet{
    grid-template-columns: repeat(8, 1fr);
  }
}

.count{
  margin-bottom: 10px;
}

.intro{
  grid-column: 2 / 6;
  @include tablet{
    grid-column: span 4;
  }
  @include mobile{
    grid-column: span 8;
  }
}
.sign-up{
  grid-column: span 4;
  margin-bottom: 60px;
  @include mobile{
    grid-column: span 8;
  }
}

.sign-up div{
  display: flex;
  width: 100%;
}

.sign-up input{
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 0;
  border-bottom: 2px solid #000000;
  background: none;
  width: 100%;
  flex-grow: 1;
  display: block;
  box-sizing: border-box;
  font-size: 14px;
  margin-right: 20px;
}

.form-wrap{
  position: relative;
}

.response{
  font-size: 14px;
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% + 0.5em));
  left: 2px;
}

input::placeholder{
  color: #000000;
}

button{
  background: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 2px solid #000000;
  border-radius: 99px;
  font-family: inherit;
  font-size: 14px;
  min-width: 100px;
}
