@font-face {
  font-family: "Standard";
  src: url("webfonts/standard-book-webfont.woff2") format("woff2"),
       url("webfonts/standard-book-webfont.woff") format("woff");
  font-weight: 400;
 	font-style: normal;
}

body {
  margin: 0;
  font-family: 'Standard', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
